













































































































import Vue from "vue";
import _ from "lodash";
import store from "./store/session";
import commonStore from "./store/common";
import { ServedCode } from "./model/entity/orderSetting";

import "./directives/cursor";
import "./directives/ripple";
import "./directives/click2top";
import { ulid } from "ulid";
import mqtt from "mqtt";

import * as animationData from "./assets/order-sending.json";

const acceptCallApi = {
  table_session_order: {
    page: [],
    callApi: function (context, data) {
      if (data.value === context.tabeSessionid) {
        store.dispatch("checkSession").then(status => {
          context.$router.push("/settle-complete");
        });
        store.dispatch("fetchMenus");
      }
    },
  },
  table_session_checkout: {
    page: [],
    callApi: function (context, data) {
      if (data.value === context.tabeSessionid) {
        store.dispatch("checkSession").then(status => {
          context.$router.push("/settle-complete");
        });
        store.dispatch("fetchMenus");
        store.dispatch("fetchOrderHistory");
      }
    },
  },
  update_menu: {
    page: [],
    callApi: function (context, data) {
      store.dispatch("fetchMenus");
    },
  },
  update_setting_shop: {
    page: [],
    callApi: function (context, data) {
      store.dispatch("checkSession");
    },
  }
};

export default Vue.component("App", {
  components: {
    SOHeader: () => import("./components/compositions/SOHeader.vue"),
    SOCart: () => import("./components/compositions/SOCart.vue"),
    SOOrderListButton: () =>
      import("./components/compositions/SOOrderListButton.vue"),
    SOMenu: () => import("./components/compositions/SOMenu.vue"),
    QTranslate: () => import("./components/elements/QTranslate.vue"),
    Lottie: () => import("./components/elements/Lottie.vue")
  },
  data() {
    return {
      lottie: {
        options: {
          loop: true,
          autoplay: true,
          animationData
        }
      },

      appStyle: {},
      scrollY: 0,
      isCartOpen: false,
      isShowThanks: false,
      connection: {
        host: '', // teslint-disable-line
        clientId: ulid(),
        keepalive: 30,
      },
      client: null,

      mode: false,
    };
  },
  created() {
    store.dispatch("load");
    commonStore.dispatch("setMode", "remote");
  },
  mounted() {
    setTimeout(() => {
      this.$router.name != 'remote-checkin' && store.dispatch("checkSession").then((status: string) => {
        this.$router.push("/settle-complete");
      });
    }, 10 * 1000);
    commonStore.dispatch('fetchHoliday');
  },
  computed: {
    isFixScroll() {
      return store.state.isFixScroll;
    },
    isInitializing(): boolean {
      return store.state.isInitializing;
    },
    histories() {
      const histories = _.filter(store.state.histories, history => {
        return (history.is_served === ServedCode.SERVED_READY && history.is_deliver === false);
      });
      return histories;
    },
    isOrdering(): boolean {
      return store.state.isOrdering;
    },
    isLoading(): boolean {
      return store.state.isLoading;
    },
    shop() {
      return store.state.shop;
    },
    wsSignedUrl() {
      return store.state.wsUrl;
    },
    isDone() {
      return store.state.isDone;
    },
    tabeSessionid() {
      return store.state.tableSession.table_session_id;
    }
  },
  methods: {
    handleUpdateTitle(title: string) {
      document.title = title;
    },
    createConnection() {
      const { host, ...options } = this.connection;
      if (this.client && this.client.connected) {
        return;
      }
      try {
        this.client && this.client.end();
        this.client = mqtt.connect(host, options);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
      this.client && this.client.on("connect", () => {
        console.log("Connection succeeded!");
        this.doSubscribe();
      });
      this.client && this.client.on("error", (error) => {
        console.log("Connection failed", error);
      });
      this.client && this.client.on("message", (topic, message) => {
        this.handleReciverMessage(topic, message);
      });

      this.client && this.client.on("reconnect", () => {
        console.log('reconnect');
        if (!this.client.connected) {
          setTimeout(() => {
            store.dispatch("getWsUrl");
          }, 5000) // auto reconnect after 5s
        }
      });
    },
    doSubscribe() {
      const { qos, topic } = {
        topic: `shop/${this.shop.id}`,
        qos: 0,
      };

      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        console.log("Subscribe to topics res", res);
      });
    },
    handleReciverMessage(topic, message) {
      let payload = JSON.parse(message);
      if (
        acceptCallApi[payload.type] &&
        acceptCallApi[payload.type].page.includes(this.page)
      ) {
        acceptCallApi[payload.type].callApi(this, payload);
      } else if (
        acceptCallApi[payload.type] &&
        acceptCallApi[payload.type].page.length === 0 // case alway call api
      ) {
        acceptCallApi[payload.type].callApi(this, payload);
      }
    },
    handleClickCloseModal: function () {
      this.mode = false;
    },
  },
  watch: {
    histories() {
      if (this.shop.is_select_receive && this.histories.length > 0) {
        this.mode = true;
      } else {
        this.mode = false;
      }
    },
    isOrdering() {
      if (this.isOrdering === false && store.state.cart.orders.length === 0) {
        this.isShowThanks = true;
        setTimeout(() => {
          this.isShowThanks = false;
        }, 5000);

        // ODR-1051
        this.$router.push('/category');
      }
    },
    isFixScroll() {
      if (this.isFixScroll) {
        this.scrollY = window.scrollY;
        this.appStyle = {
          top: `-${scrollY}px`,
          position: "fixed"
        };
      } else {
        this.appStyle = {};
        setTimeout(() => {
          window.scrollTo({
            left: 0,
            top: this.scrollY,
            behavior: "smooth"
          });
        }, 100);
      }
    },
    shop: function(newVal, oldVal) {
      if (newVal && newVal.id) {
        if (!(this.client && this.client.connected)) {
          store.dispatch('getWsUrl');
        }
      } else if (!newVal) {
        !['error', 'remote-settle-complete'].includes(this.$router.currentRouter.name)
        && this.$router.push('error');
      }
    },
    wsSignedUrl: function(newVal, oldVal) {
      console.log("Watch :" + newVal);
      this.connection.host = newVal;
      this.createConnection();
    },
    isDone: function(newVal) {
      if (newVal && this.client) {
        console.log('end connect wss');
        this.client.end();
      }
    }
  }
});
