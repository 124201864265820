import Vuex from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import { ulid } from 'ulid';
import moment from 'moment';
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
import { TableSession } from '@/model/entity/session';
import FrontAPI from '../common/api/api_front';
import { Shop } from '../model/entity/shop';
import { Cart } from '../model/entity/cart';
import * as orderHistory from "../model/orderHistory";
import * as tableInfo from "../model/tableInfo";
import { ClientOrder } from '../model/entity/client_order';
import { Category, Effect, Menu, MenuOption, MenusMenuOption } from '../model/menu';
import { DeviceCodeServiceBuilder } from '../services/DeviceCode/deviceCodeServiceBuilder';
import { CategoryGroup } from "../model/entity/category_group";

Vue.use(Vuex);

// data
const api = new FrontAPI();
let tableSession: TableSession;
let shop: Shop = new Shop();
let categories: Category[] = [];
let effects: Effect[] = [];
let cart: Cart = new Cart();

// cache setting
const version = '1.001';
const storeKey = `cache.table_session-${version}`;
let cacheMenus: { [key: string]: Menu } = {};
let cacheMenuOptions: { [key: string]: MenuOption } = {};
let cacheMenusMenuOption: { [key: string]: MenusMenuOption } = {};
const categoryGroups: CategoryGroup[] = [];

const sleep = (time: number): Promise<void> => {
    return new Promise((res, rej) => {
        setTimeout(() => {
            return res();
        }, time);
    })
};
const vuexLocal = new VuexPersistence({
    storage: localForage,
    asyncStorage: true,
    key: storeKey,
});

const state = {
    wsUrl: '',
    code: '',
    tableSession,

    langKey: 'ja_JP',
    staticTranslates: {} as {[key: string]: {[key: string]: string}},
    langs: [] as { name: string, locale: string, is_enable: boolean}[],
    shopLangs: [] as { name: string, locale: string, is_enable: boolean}[],

    cart,

    histories: [],
    paymentHistories: [],
    summary: {},
    shop,
    categoryGroups,
    categories,
    effects,
    cacheMenus,
    cacheMenuOptions,
    cacheMenusMenuOption,

    isFixScroll: false,
    isLoading: false,
    isInitializing: false,
    isOrdering: false,
    cartUniqKey: "",
    errors: null,
    isDone: false,
};

const sessionStore = new Vuex.Store<typeof state>({
    state,

    plugins: [vuexLocal.plugin],

    getters: {
        menuOfCart: state => () => {
            let menus = _.clone(state.cart.orders);
            return menus;
        },
        effectLimitTitle: () => (effect: Effect) => {
            if (effect.end_time) {
                const endTime = moment(effect.end_time).format("HH:mm");
                const lastTime = moment(effect.last_order_time).format("HH:mm");
                return `${endTime} (L.O. ${lastTime})`;
            } else {
                return '--:-- (L.O. --:--)';
            }
        },
        isEffectAvaiable: () => (effect: Effect) => {
            // enable if not set limit item
            if (!effect.limit_option) {
                return true;
            }
            // check valid time
            const now = moment();
            return effect.start_time &&
                (moment(effect.start_time).isBefore(now) && moment(effect.last_order_time).isAfter(now));
        },
        effectStatus: () => (effect: Effect): "active"|"prepare"|"end" => {
            // enable if not set limit item
            if (!effect.limit_option) {
                return 'active';
            }

            if (!effect.end_time) {
                return 'prepare';
            }

            // check valid time
            const now = moment();
            const check = effect.start_time &&
                (moment(effect.start_time).isBefore(now) && moment(effect.last_order_time).isAfter(now));

            if (!check) {
                return 'end';
            }

            return 'active';
        },
    },

    actions: {
        /**
         * 全ての初期化処理を行う
         */
        init({ dispatch, commit }) {
            dispatch('initDeviceCode');
            dispatch('initLangKey');

            commit('resetShop');
        },
        load({ commit }) {
            commit('setIsFixScroll', false);
        },
        clearSession({ commit }) {
            commit('setTableSessionId', null);
            commit('setTableSessionId', null);
            commit('setCode', null);
            commit('resetShop');
        },

        initCode({ commit }, code: string) {
            commit('setCode', code);
        },

        /**
         * デバイスコードを初期化する
         */
        initDeviceCode({ commit, state }) {
            if (!state.tableSession) {
                commit('setInitSession');
            }

            if (!state.tableSession.device_code) {
                const deviceCode = DeviceCodeServiceBuilder.Instance().genDeviceCode();
                commit('setDeviceCode', deviceCode);
            }
        },

        /**
         * 言語設定を初期化する
         */
        initLangKey({ commit, state }) {
            if (!state.langKey) {
                commit('setInitLangKey');
            }
        },
        selectLangKey({ commit }, langKey: string) {
            commit('setLangKey', langKey);
        },

        /**
         * チェックイン処理
         */
        checkin({ commit, state }, tableSessionId) {
            commit('setInitializing', true);
            commit('setLoading', true);
            if (tableSessionId !== state.tableSession.table_session_id) {
                commit('resetCart');
            }

            return api.checkin({
                table_session_id: tableSessionId,
                device_code: state.tableSession.device_code,
            }, {
                lang: state.langKey,
            })
                .then((response) => {
                    if (response.data.status !== 'success') {
                        throw new Error(response.data.message);
                    }

                    commit('setTableSessionId', tableSessionId);
                    commit('setTableSession', response.data.data.table_session);
                    commit('setShop', response.data.data.shop);

                    return response.data;
                })
                .then(() => {
                    return api.translate({ shop_id: state.shop.id })
                })
                .then((response) => {
                    // if (response.data.status !== 'success') {
                    //     throw new Error(response.data.message);
                    // }

                    commit('setStaticTranslate', response.data.data.static);
                    commit('setLangs', response.data.data.languages);
                    commit('setShopLangs', response.data.data.shopLanguages);
                })
                .finally(() => {
                    commit('setInitializing', false);
                    commit('setLoading', false);
                });
        },
        async checkSession({ commit, state }) {
          const device_code = (state.tableSession || { device_code: '' }).device_code;
            if (!device_code) {
                return 'init';
            }

            const checker = () => {
                return api.checkin({
                    table_session_id: state.tableSession.table_session_id,
                    device_code: state.tableSession.device_code,
                }, {
                    lang: state.langKey,
                })
                    .then((response) => {
                      commit('setShop', response.data.data.shop);
                      console.log(response.data.status)
                      return response.data.status;
                    });
            }
                try {
                    const result = await checker();
                console.log('result', result)
                    if (result !== 'success') {
                        return Promise.resolve(result);
                    }
                return Promise.reject(result);
                } catch (err) {
                    if (err.response.data.status === 'invalid') {
                        return Promise.resolve('invalid');
                    }
                }
        },

        fetchMenus({ commit, state }) {
            commit('setLoading', true);

            return api.menu({ lang: state.langKey, shop_id: state.shop.id, table_session_id: state.tableSession.table_session_id })
                .then((response) => {
                    if (response.data.status !== 'success') {
                        throw new Error(response.data.message);
                    }

                    commit('setCategories', response.data.data.categories);
                    commit('setCategoryGroups', response.data.data.categoryGroups);
                    commit('setEffects', response.data.data.effects);
                    return response.data;
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        },
        // async checkMenus() {
        //     const checker = () => {
        //         return this.dispatch('fetchMenus');
        //     };
        //     const interval = 20000;

        //     while (true) {
        //         try {
        //             await checker();
        //         } catch (err) {
        //             console.error(err);
        //         }
        //         await sleep(interval);
        //     }
        // },
        postOrder({ commit, state }, isDeliver: string) {
            commit('setLoading', true);
            commit('setOrdering', true);

            return api.order({
                table_session_id: state.tableSession.table_session_id,
                customer_info: {
                    device_code: state.tableSession.device_code,
                },
                // menus: state.cart.orders,
                menus: this.getters.menuOfCart(),
                request_id: state.cartUniqKey,
                is_deliver: isDeliver,
            }, {
                lang: state.langKey,
            })
                .then((response) => {
                    commit('errors', response.data.errors || null);
                    if (response.data.status !== 'success') {
                        throw new Error(response.data.message);
                    }

                    commit('resetCart');

                    return response.data;
                })
                .finally(() => {
                    commit('setLoading', false);
                    commit('setOrdering', false);
                    this.dispatch('fetchMenus');
                });
        },
        fetchOrderHistory({ commit, state }) {
            commit('setLoading', true);

            return api.orderHistory({
                device_code: state.tableSession.device_code,
                table_session_id: state.tableSession.table_session_id,
                lang: state.langKey,
            })
                .then((response) => {
                    if (response.data.status !== 'success') {
                        throw new Error(response.data.message);
                    }
                    commit('setOrderHistory', {response});

                    return response.data;
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        },
        fetchTableInfo({ commit, state }) {
            commit('setLoading', true);

            return api.tableInfo({
                table_session_id: state.tableSession.table_session_id,
            })
                .then((response) => {
                    if (response.data.status !== 'success') {
                        throw new Error(response.data.message);
                    }
                    commit('setTableSession', response.data.data.table_session);

                    return response.data;
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        },

        addOrder({ commit }, payload: { order: ClientOrder, index: number }) {
            commit('setOrder', payload);
            commit('setCartKey');
        },
        removeOrder({ commit }, payload: { index: number }) {
            commit('removeOrder', payload);
            commit('setCartKey');
        },
        getWsUrl({commit}) {
            api.getSignedWss().then(response => {
              if (response.data.status != 'success') {
                throw new Error('fail to get ws url');
              }
              commit('setWsUrl', response.data.data.signed_url);
            })
        }
    },

    mutations: {
        /**
         * コード情報を保持する
         */
        setCode(state, code: string) {
            state.code = code;
        },

        /**
         * テーブルセッションを初期化する
         */
        setInitSession(state) {
            state.tableSession = {
                table_session_id: '',
                device_code: '',
                no: -1,
                table_no: -1,
                quantities: 1,
                table_seat: null,
            };
        },

        /**
         * 表示言語キーを初期化する (日本語)
         */
        setInitLangKey(state) {
            state.langKey = 'ja_JP';
        },
        setLangKey(state, key: string) {
            state.langKey = key;
        },

        /**
         * デバイスコードを設定する
         */
        setDeviceCode(state, deviceCode: string) {
            state.tableSession.device_code = deviceCode;
        },

        /**
         * テーブルセッションIDを設定する
         */
        setTableSessionId(state, tableSessionId: string) {
            state.tableSession.table_session_id = tableSessionId;
        },
        setTableSession(state, tableSession: TableSession) {
            state.tableSession.no = tableSession.no;
            state.tableSession.table_no = tableSession.table_no;
            state.tableSession.quantities = tableSession.quantities;
            state.tableSession.table_seat = tableSession.table_seat;
        },

        /**
         * 店舗情報を設定する
         */
        setShop(state, shop: Shop) {
            state.shop = shop;
        },
        resetShop(state) {
            state.shop = new Shop();
            state.categories = [];

            // cache の削除
            state.cacheMenus = {};
            state.cacheMenuOptions = {};
            state.cacheMenusMenuOption = {};
        },

        /***
         * カテゴリー、メニュー情報を設定する
         */
        setCategories(state, categories: Category[]) {
            state.categories = categories;

            // make cache
            const menuMap = _.flatten(_.map(categories, cat => cat.menus));
            const menuOptionMap = _.flatten(_.map(menuMap, menu => menu.menu_options));
            const menusMenuOptionMap = _.flatten(_.map(menuOptionMap, mmop => mmop.menus_menu_options));

            for (const menu of menuMap) {
                state.cacheMenus[menu.id] = menu;
            }
            for (const moption of menuOptionMap) {
                state.cacheMenuOptions[moption.id] = moption;
            }
            for (const mmoption of menusMenuOptionMap) {
                state.cacheMenusMenuOption[mmoption.id] = mmoption;
            }
        },
        setCategoryGroups(state, data) {
        state.categoryGroups = data;
      },

        /***
         * set Effects
         */
        setEffects(state, effects: Effect[]) {
            // TODO: consider uniq by category_option_id
            // state.effects = _.uniqBy(effects, 'category_option_id');
            effects.forEach((effect: Effect) => {
              if (effect.category_option) {
                effect.category_option.categories.forEach((category) => {
                  category.menus.forEach((menu) => {
                    state.cacheMenus[menu.id] = menu;
                  });
                });
              }
            });
            state.effects = effects;
        },

        /**
         * 商品をカートに追加
         */
        setOrder(state, payload: { order: ClientOrder, index: number }) {
            if (payload.index == -1) {
                // new
                state.cart.orders.push(payload.order);
            } else {
                // update
                state.cart.orders[payload.index] = payload.order;
            }
        },

        /**
         * 注文履歴を更新
         */
        setOrderHistory(state, payload: { response: { data: orderHistory.Response } }) {
            console.log(payload.response);
            state.histories = payload.response.data.data.histories || [];
            state.paymentHistories = payload.response.data.data.paymentHistories || [];
            state.summary = payload.response.data.data.summary || [];
        },
        removeOrder(state, payload: { index: number }) {
            const tmp = _.cloneDeep(state.cart.orders);
            tmp.splice(payload.index, 1);
            // console.log(tmp, removed);
            state.cart.orders = tmp;
        },
        resetCart(state) {
            state.cartUniqKey = ulid();
            state.cart = new Cart();
        },
        errors(state, errors) {
            state.errors = errors;
        },
        setCartKey(state) {
            state.cartUniqKey = ulid();
        },

        /**
         * 静的翻訳データを保持
         */
        setStaticTranslate(state, staticTranslates: { [key: string]: { [key: string]: string } }) {
            state.staticTranslates = staticTranslates;
        },
        setLangs(state, langs: { name: string, locale: string, is_enable: boolean }[]) {
            state.langs = langs;
        },

        /**
         * 店舗の翻訳設定を保持
         */
        setShopLangs(state, shopLangs: { name: string, locale: string, is_enable: boolean}[]) {
            state.shopLangs = shopLangs;
        },

        /**
         * ネットワーク読み込み状態を設定する
         */
        setLoading(state, loading) {
            state.isLoading = loading;
        },
        setInitializing(state, loading) {
            state.isInitializing = loading;
        },
        setOrdering(state, loading) {
            state.isOrdering = loading;
        },
        setIsFixScroll(state, isFix) {
            state.isFixScroll = isFix;
        },
        setWsUrl(state, url: string) {
            state.wsUrl = url;
        },
        isDone(state, flag) {
            state.isDone = flag;
        }
    },
});

export default sessionStore;
