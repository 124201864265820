import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './Remote.vue';
import './registerServiceWorker';
import { Env } from './common/util/env';
import * as Sentry from '@sentry/vue';
import { Integrations } from "@sentry/tracing";
import LogRocket from 'logrocket';
import store from './store/session'; // the location of Vuex store

LogRocket.init('vceehl/vivo-5hkgt');
Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/checkin',
    },
    {
        path: '/checkin',
        component: () => import('./pages/remote/Checkin.vue'),
        name: 'remote-checkin',
    },
    {
        path: '/category',
        component: () => import('./pages/remote/Category.vue'),
        name: 'remote-category',
    },
    {
        path: '/category_group/:category_group_id',
        component: () => import('./pages/remote/CategoryGroup.vue'),
        name: 'remote-category-group',
    },
    {
        path: '/menu/:category_id',
        component: () => import('./pages/remote/Menu.vue'),
        name: 'remote-menu-category',
    },
    {
        path: '/menu/:category_id/:menu_id',
        component: () => import('./pages/remote/Menu.vue'),
        name: 'remote-menu-category-menu',
    },
    {
        path: '/payment',
        component: () => import('./pages/remote/Payment.vue'),
        name: 'remote-payment',
    },
    {
        path: '/order-history',
        component: () => import('./pages/remote/OrderHistory.vue'),
        name: 'remote-order-history',
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/remote/Error.vue'),
    },
    {
        path: '/settle-complete',
        component: () => import('./pages/remote/SettleComplete.vue'),
        name: 'remote-settle-complete',
    },
    {
        path: '/check',
        component: () => import('./pages/remote/Check.vue'),
        name: 'remote-check',
    },
    {
        path: '/*',
        redirect: '/error',
    }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: Env.ROUTER_BASE_REMOTE,
});

router.beforeEach(async (to, from, next) => {
  await (store as unknown as any).restored;
  next();
});

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracingOptions: {
        trackComponents: true,
    },
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
